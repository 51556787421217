.ShurikenProgress {
    animation: shurikenProgress 1s infinite;
}

@keyframes shurikenProgress {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
